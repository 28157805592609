import React, { useEffect, useState } from "react";
import Game from "./Game";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FunMode = ({
  setModeToggle,
  modeToggle,
  setTheme,
  theme,
  modeRenderEffect,
  modeSwitch,
  bgMusicRef,
  coinFlipRef,
  betBtnRef,
  toogleSoundRef,
  winSoundRef,
  loseSoundRef,
  internetStatus,
  advertisingImage,
}) => {
  const [streak, setStreak] = useState(0);
  const [creditPoints, setCreditPoints] = useState(10000);
  const [totalWin, setTotalWin] = useState(0);
  const [totalLose, setTotalLose] = useState(0);
  const [btnDisable, setBtnDisable] = useState(false);
  const [winAmount, setWinAmount] = useState(0);
  const [totalWinAmount, setTotalWinAmount] = useState(0);
  const [result, setResult] = useState(null);
  const [trackResult, setTrackResult] = useState(null);
  const [userChoose, setUserChoose] = useState(null);
  const [bidValue, setBidValue] = useState(1);
  const [winStatus, setWinStatus] = useState(null);
  const [coinFace, setCoinFace] = useState("head");
  const [resultStatus, setResultStatus] = useState(false);

  // sound checker
  const [isSoundPause, setIsSoundPause] = useState(null);

  useEffect(() => {
    const screenChange = (event) => {
      if (document.visibilityState === "visible") {
        // console.log("active");
        setTimeout(() => {
          setResultStatus(false);
        }, 3000);
      }
    };
    document.addEventListener("visibilitychange", screenChange);

    return () => document.removeEventListener("visibilitychange", screenChange);
  }, []);

  // handleCoinToss
  // const coinToss = (value, audio) => {
  //   const random = Math.floor(Math.random() * 1000 + 1);
  //   if (random % 2 === 0) {
  //     setResult("tail");
  //     setTimeout(() => {
  //       audio.play();
  //     }, 100);
  //     setTrackResult("tail");
  //     if (value === "tail") {
  //       setTimeout(() => {
  //         setTotalWin((prev) => prev + 1);
  //         setWinAmount(bidValue * 2);
  //         setTotalWinAmount((prev) => prev + bidValue);
  //         setCreditPoints((prev) => prev + bidValue * 2);
  //         setStreak((prev) => prev + 1);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(true);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("tail");
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             winSoundRef.current.pause();
  //             setIsSoundPause("win");
  //           } else {
  //             winSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     } else {
  //       setTimeout(() => {
  //         setTotalLose((prev) => prev + 1);
  //         setWinAmount(0);
  //         setStreak(0);
  //         setTotalWinAmount((prev) => prev + 0);
  //         setCreditPoints((prev) => prev + 0);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(false);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("tail");
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             loseSoundRef.current.pause();
  //             setIsSoundPause("lose");
  //           } else {
  //             loseSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     }
  //   } else {
  //     setResult("head");
  //     setTimeout(() => {
  //       audio.play();
  //     }, 100);
  //     setTrackResult("head");
  //     if (value === "head") {
  //       setTimeout(() => {
  //         setTotalWin((prev) => prev + 1);
  //         setStreak((prev) => prev + 1);
  //         setWinAmount(bidValue * 2);
  //         setTotalWinAmount((prev) => prev + bidValue);
  //         setCreditPoints((prev) => prev + bidValue * 2);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(true);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("head");
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             winSoundRef.current.pause();
  //             setIsSoundPause("win");
  //           } else {
  //             winSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     } else {
  //       setTimeout(() => {
  //         setTotalLose((prev) => prev + 1);
  //         setWinAmount(0);
  //         setStreak(0);
  //         setCreditPoints((prev) => prev + 0);
  //         setTotalWinAmount((prev) => prev + 0);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(false);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("head");
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             loseSoundRef.current.pause();
  //             setIsSoundPause("lose");
  //           } else {
  //             loseSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     }
  //   }
  // };

  const coinToss = (value, audio) => {
    const random = Math.floor(Math.random() * 10 + 1);
    const win = random <= 3;
    const result = win ? value : value === "head" ? "tail" : "head";
    // console.log(result);
    setResult(result);
    setTimeout(() => {
      audio.play();
    }, 100);
    setTrackResult(result);
    if (value === result) {
      setTimeout(() => {
        setTotalWin((prev) => prev + 1);
        setWinAmount(bidValue * 2);
        setTotalWinAmount((prev) => prev + bidValue);
        setCreditPoints((prev) => prev + bidValue * 2);
        setStreak((prev) => prev + 1);
        if (document.hidden || document.webkitHidden) {
          setWinStatus(null);
          setResultStatus(true);
          setBtnDisable(false);
        } else {
          setWinStatus(true);
          setResultStatus(true);
        }
        setResult(null);
        setUserChoose(value);
        setCoinFace(result);
        setTimeout(() => {
          if (document.hidden || document.webkitHidden) {
            winSoundRef.current.pause();
            setIsSoundPause("win");
          } else {
            winSoundRef.current.play();
            setIsSoundPause(null);
          }
        }, 300);
        if (!document.hidden || !document.webkitHidden) {
          setTimeout(() => {
            setWinStatus(null);
            setResultStatus(false);
            setBtnDisable(false);
          }, 2500);
        }
      }, 3400);
    } else {
      setTimeout(() => {
        setTotalLose((prev) => prev + 1);
        setWinAmount(0);
        setStreak(0);
        setTotalWinAmount((prev) => prev + 0);
        setCreditPoints((prev) => prev + 0);
        if (document.hidden || document.webkitHidden) {
          setWinStatus(null);
          setResultStatus(true);
          setBtnDisable(false);
        } else {
          setWinStatus(false);
          setResultStatus(true);
        }
        setResult(null);
        setUserChoose(value);
        setCoinFace(result);
        setTimeout(() => {
          if (document.hidden || document.webkitHidden) {
            loseSoundRef.current.pause();
            setIsSoundPause("lose");
          } else {
            loseSoundRef.current.play();
            setIsSoundPause(null);
          }
        }, 300);
        if (!document.hidden || !document.webkitHidden) {
          setTimeout(() => {
            setWinStatus(null);
            setResultStatus(false);
            setBtnDisable(false);
          }, 2500);
        }
      }, 3400);
    }
  };

  // handleBetCoin
  const handleBet = (value, audio) => {
    if (creditPoints >= bidValue) {
      setCreditPoints((prev) => prev - bidValue);
      setBtnDisable(true);
      coinToss(value, audio);
    } else if (creditPoints <= 50) {
      setCreditPoints(10000);
    } else {
      toast("Insufficient funds", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
    }
  };

  return (
    <>
      <Game
        modeToggle={modeToggle}
        setModeToggle={setModeToggle}
        streak={streak}
        creditPoints={creditPoints}
        totalWin={totalWin}
        totalLose={totalLose}
        bidValue={bidValue}
        setBidValue={setBidValue}
        totalWinAmount={totalWinAmount}
        result={result}
        trackResult={trackResult}
        btnDisable={btnDisable}
        winAmount={winAmount}
        handleBet={handleBet}
        userChoose={userChoose}
        setUserChoose={setUserChoose}
        winStatus={winStatus}
        setWinStatus={setWinStatus}
        coinFace={coinFace}
        theme={theme}
        setTheme={setTheme}
        modeRenderEffect={modeRenderEffect}
        modeSwitch={modeSwitch}
        bgMusicRef={bgMusicRef}
        coinFlipRef={coinFlipRef}
        betBtnRef={betBtnRef}
        toogleSoundRef={toogleSoundRef}
        winSoundRef={winSoundRef}
        loseSoundRef={loseSoundRef}
        internetStatus={internetStatus}
        isSoundPause={isSoundPause}
        setIsSoundPause={setIsSoundPause}
        resultStatus={resultStatus}
        advertisingImage={advertisingImage}
      />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={!theme ? "dark" : "light"}
      />
    </>
  );
};

export default FunMode;
