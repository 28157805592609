import React, { useEffect, useState } from "react";
import "../styles/Game.css";
import "../styles/DropDown.css";
import "../styles/InternetConnection.css";
// icons
import rules from "../assets/rules.png";
import hotkey from "../assets/hotkeys.png";
import stats from "../assets/stats.png";
import wallet from "../assets/wallet.png";
import setting from "../assets/setting.png";
import hamBurger from "../assets/ham-burger.png";
import namiWallet from "../assets/nami-icon.png";
import eternlWallet from "../assets/eternl-icon.png";
import flintWallet from "../assets/flint-icon.png";
import user from "../assets/user.png";
import addIcon from "../assets/addTokenIcon.png";

// audios
import coinSound from "../assets/audio/coin-spin-sound.mp3";
import winSound from "../assets/audio/win-sound.mp3";
import loseSound from "../assets/audio/lose-sound.mp3";

// game components
import BetBtn from "../components/BetBtn";
import Rules from "./Rules";
import Setting from "./Setting";
import Dashboard from "./Dashboard";
import Stats from "./Stats";
import Leaderboard from "./Leaderboard";
import AddToken from "./AddToken";
import RedeemToken from "./RedeemToken";

// library
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";

// game images
import head from "../assets/head.png";
import tail from "../assets/tail.png";
import resultCoinHead from "../assets/fade-head.png";
import resultCoinTail from "../assets/fade-tail.png";
import min from "../assets/min.png";
import max from "../assets/max.png";
import minus from "../assets/minus.png";
import plus from "../assets/plus.png";
import half from "../assets/half.png";
import twoX from "../assets/twoX.png";
import betHead from "../assets/bet-head-1.png";
import betTail from "../assets/bet-tail-1.png";
import bidCoin from "../assets/bid-coins.png";
import rightArrow from "../assets/gameMode.png";
import winMsg from "../assets/youwin.png";
import loseMsg from "../assets/youlose.png";
import moon from "../assets/moon.png";
import sun from "../assets/sun.png";
import whiteMoon from "../assets/white-moon.png";
import whiteSun from "../assets/white-sun.png";

import leftGlass from "../assets/left-glass.png";
import middleGlass from "../assets/middle-glass.png";
import rightGlass from "../assets/right-glass.png";

////////////// dark ///////////////////
import darkBg from "../assets/main-bg.png";
import darkTabletBg from "../assets/tablet-bg.png";

////////////// light ///////////////////
import lightBg from "../assets/whiteTheme/white-bg.png";
import lightBetBtnBg from "../assets/whiteTheme/bet-btn-bg.png";
import TermsCondition from "./TermsCondition";
import Transaction from "./Transaction";
import HotKeys from "./HotKeys";
import { useHotkeys } from "react-hotkeys-hook";
import { useMediaQuery } from "react-responsive";

import advertisingImageStatic from "../assets/advertising-1.png";
import axios from "axios";
import { URL } from "../APIs/axiosConfig";

const Game = ({
  modeToggle,
  setModeToggle,
  streak,
  creditPoints,
  handleNamiWallet,
  walletAdd,
  totalWin,
  totalLose,
  totalStreak,
  bidValue,
  setBidValue,
  totalWinAmount,
  result,
  trackResult,
  btnDisable,
  winAmount,
  userChoose,
  handleBet,
  setUserChoose,
  winStatus,
  setWinStatus,
  coinFace,
  getDashboardData,
  gameDashboardData,
  theme,
  setTheme,
  userStats,
  modeRenderEffect,
  modeSwitch,
  API,
  userToken,
  setCreditPoints,
  amountLoader,
  setAmountLoader,
  bgMusicRef,
  coinFlipRef,
  betBtnRef,
  toogleSoundRef,
  winSoundRef,
  loseSoundRef,
  getTokenAPI,
  internetStatus,
  isSoundPause,
  setIsSoundPause,
  activeWalletName,
  resultStatus,
  advertisingImage,
}) => {
  // Screen Management
  const COIN_TRANSLATE_WIDTH = ((11.27176 + 64) / 5840) * 100;
  // let utxosCount = 0;
  const [ruleScreen, setRuleScreen] = useState(false);
  const [hotkeyScreen, setHotkeyScreen] = useState(false);
  const [statsScreen, setStatsScreen] = useState(false);
  const [transactionScreen, setTransactionScreen] = useState(false);
  const [settingScreen, setSettingScreen] = useState(false);
  const [dashBoardScreen, setDashBoardScreen] = useState(false);
  const [leaderBoardScreen, setLeaderBoardScreen] = useState(false);
  const [networkScreen, setNetworkScreen] = useState(false);

  // rule screen effect
  const [ruleEffect, setRuleEffect] = useState(true);
  const [statsEffect, setStatsEffect] = useState(true);
  const [hotkeyEffect, setHotKeyEffect] = useState(true);
  const [transactionEffect, setTransactionEffect] = useState(true);

  // state management
  const [termsCondition, setTermsCondition] = useState(false);
  const [addTokenScreen, setAddTokenScreen] = useState(false);
  const [redeemTokenScreen, setRedeemTokenScreen] = useState(false);
  const [resultImages, setResultImages] = useState([]);
  const [resultWidth, setResultWidth] = useState(100);
  const [hotkeyToggle, setHotkeyToggle] = useState(false);
  const [dropDownWallet, setDropDownWallet] = useState(false);
  const [Utxos, setUtxos] = useState([]);
  const [disable, setDisable] = useState(false);
  const [activeWallet, setActiveWallet] = useState(false);
  const [platform, setPlatform] = useState(null);

  const maxWidth700 = useMediaQuery({ query: "(max-width: 700px)" });
  const maxHeight700 = useMediaQuery({ query: "(max-height: 700px)" });

  // Reference of audios
  const audio = betBtnRef.current;
  const currentToggle = toogleSoundRef.current;

  const closeAllScreens = () => {
    setRuleScreen(false);
    setStatsScreen(false);
    setHotkeyScreen(false);
    setTransactionScreen(false);
    setSettingScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
  };

  useEffect(() => {
    if (internetStatus) {
      setTimeout(() => {
        setNetworkScreen(false);
      }, 1000);
    } else if (internetStatus === false) {
      setNetworkScreen(true);
      closeAllScreens();
    }
  }, [internetStatus]);

  // add result
  useEffect(() => {
    if (!userChoose) {
      return;
    }
    if (userChoose === "tail" && trackResult === "tail") {
      setResultImages([tail, ...resultImages]);
      setUserChoose(null);
      setResultWidth((prev) => prev - COIN_TRANSLATE_WIDTH);
    } else if (userChoose === "tail" && trackResult !== "tail") {
      setResultImages([resultCoinHead, ...resultImages]);
      setUserChoose(null);
      setResultWidth((prev) => prev - COIN_TRANSLATE_WIDTH);
    } else if (userChoose === "head" && trackResult === "head") {
      setResultImages([head, ...resultImages]);
      setUserChoose(null);
      setResultWidth((prev) => prev - COIN_TRANSLATE_WIDTH);
    } else {
      setResultImages([resultCoinTail, ...resultImages]);
      setUserChoose(null);
      setResultWidth((prev) => prev - COIN_TRANSLATE_WIDTH);
    }
  }, [
    userChoose,
    COIN_TRANSLATE_WIDTH,
    resultImages,
    setUserChoose,
    trackResult,
  ]);

  // min bid functio4
  const handleMinBid = () => {
    audio?.play();
    setBidValue(1);
  };

  // max bid function
  const handleMaxBid = () => {
    audio?.play();
    setBidValue(1000);
  };

  // half bid function
  const handleHalfBid = () => {
    audio?.play();
    if (bidValue <= 2) {
      setBidValue(1);
      return;
    }
    audio?.play();
    setBidValue((prev) => prev / 2);
  };

  // 2x bid function
  const handle2xBid = () => {
    audio?.play();
    if (bidValue >= 500) {
      setBidValue(1000);
      return;
    }
    audio?.play();
    setBidValue((prev) => prev * 2);
  };

  // increase bid function
  const handleIncreaseBid = () => {
    audio?.play();
    if (bidValue >= 999) {
      setBidValue(1000);
      return;
    }
    audio?.play();
    setBidValue((prev) => prev + 1);
  };

  // decrease bid function
  const handleDecreaseBid = () => {
    audio?.play();
    if (bidValue <= 2) {
      setBidValue(1);
      return;
    }
    audio?.play();
    setBidValue((prev) => prev - 1);
  };

  // rule screen
  const handleRuleScreen = () => {
    audio?.play();
    setStatsScreen(false);
    setTransactionScreen(false);
    setHotkeyScreen(false);
    setSettingScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    if (ruleScreen) {
      setRuleEffect(false);
      setTimeout(() => {
        setRuleScreen(false);
      }, 800);
      return;
    }
    setRuleScreen(true);
    setRuleEffect(true);
  };

  // stats screen
  const handleStatsScreen = () => {
    audio?.play();
    setSettingScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    if (modeSwitch) {
      toast("Switch to Real Mode first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setRuleScreen(false);
    setTransactionScreen(false);
    setHotkeyScreen(false);
    if (statsScreen) {
      setStatsEffect(false);
      setTimeout(() => {
        setStatsScreen(false);
      }, 800);
      return;
    }
    setStatsScreen(true);
    setStatsEffect(true);
  };

  // handleTransactionPage
  const handleTransactionPage = () => {
    audio?.play();
    if (!walletAdd) {
      toast("You must login first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    if (transactionScreen) {
      setTransactionEffect(false);
      setTimeout(() => {
        setTransactionScreen(false);
      }, 800);
      return;
    }
    setTransactionScreen(true);
    setTransactionEffect(true);
  };

  const handleGameWallet = (walletName) => {
    audio?.play();
    setSettingScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    if (modeSwitch) {
      toast("Switch to Real Mode first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setDropDownWallet((prev) => !prev);
    if (walletName === "nami") {
      handleNamiWallet(walletName);
      // } else if (walletName === "eternl") {
      // } else if (walletName === "flint") {
      //   handleNamiWallet(walletName);
    } else if (walletName === "disconnect") {
      handleNamiWallet(walletName);
    }
  };

  const handleAddToken = () => {
    audio.play();
    setDropDownWallet(false);
    if (modeSwitch) {
      toast("Switch to Real Mode first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    if (!walletAdd) {
      toast("You must login first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setSettingScreen(false);
    setDashBoardScreen(false);
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setLeaderBoardScreen(false);
    setRedeemTokenScreen(false);
    setTransactionScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(true);
  };

  const handleThemeMode = () => {
    if (theme) {
      document.body.className = "light-theme";
    } else {
      document.body.className = "dark-theme";
    }
  };

  useEffect(() => {
    handleThemeMode();
    // eslint-disable-next-line
  }, [theme]);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
  }, [theme]);

  const handleHotkeys = () => {
    audio?.play();
    setRuleScreen(false);
    setTransactionScreen(false);
    setStatsScreen(false);
    setSettingScreen(false);
    setDashBoardScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    if (hotkeyScreen) {
      setHotKeyEffect(false);
      setTimeout(() => {
        setHotkeyScreen(false);
      }, 800);
      return;
    }
    setHotkeyScreen(true);
    setHotKeyEffect(true);
  };

  useEffect(() => {
    setHotkeyToggle(JSON.parse(localStorage.getItem("hotKeyEnable")));
  }, []);

  const isGameScreenOn = () => {
    if (
      btnDisable ||
      ruleScreen ||
      hotkeyScreen ||
      dashBoardScreen ||
      statsScreen ||
      leaderBoardScreen ||
      addTokenScreen ||
      redeemTokenScreen ||
      termsCondition ||
      transactionScreen ||
      settingScreen ||
      networkScreen
    ) {
      return true;
    }
    return false;
  };

  // Hot keys functionality
  useHotkeys(
    "q",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handleBetButtons("head");
    },
    {
      enabled: hotkeyToggle,
    }
  );
  useHotkeys(
    "w",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handleBetButtons("tail");
    },
    {
      enabled: hotkeyToggle,
    }
  );
  useHotkeys(
    "a",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handleMinBid();
    },
    {
      enabled: hotkeyToggle,
    }
  );
  useHotkeys(
    "s",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handleHalfBid();
    },
    {
      enabled: hotkeyToggle,
    }
  );
  useHotkeys(
    "d",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handle2xBid();
    },
    {
      enabled: hotkeyToggle,
    }
  );
  useHotkeys(
    "b",
    () => {
      if (isGameScreenOn()) {
        return;
      }
      audio?.play();
      handleMaxBid();
    },
    {
      enabled: hotkeyToggle,
    }
  );

  const handleBetButtons = (value) => {
    const audio2 = coinFlipRef.current;
    handleBet(value, audio2);
    audio?.play();
  };

  // handle wallet active color
  useEffect(() => {
    const walletName = JSON.parse(localStorage.getItem("login"));
    if (walletName?.length) {
      setActiveWallet(walletName[1]);
    }
  }, []);

  // set platform to ios
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform("ios");
    }
  }, []);

  // Prevent to right click on an directly use image
  useEffect(() => {
    const image = document.getElementById("image-prevent");
    image.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

  const [getImage, setGetImage] = useState(null);
  const [imageAvailable, setImageAvailable] = useState(true);

  useEffect(() => {
    axios
      .get(`${URL}/api/get_Adsimage`)
      .then((response) => {
        const imageData = response.data.Ads_Image;
        if (imageData) {
          setGetImage(imageData);
        } else {
          setImageAvailable(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setImageAvailable(false);
      });
  }, []);

  //  console.log("getImage",getImage);

  useEffect(() => {
    const img = document.querySelector(".add-banner");
    img.addEventListener("error", function (event) {
      // console.log("advertisingImageStatic", event);
      event.target.src = advertisingImageStatic;
      event.onerror = null;
    });
  }, []);

  return (
    <>
      {/* /////// WIN & LOSE MSG //////// */}
      <img
        src={winMsg}
        alt="win-message"
        className={
          winStatus ? "result-message result-visible" : "result-message"
        }
        draggable={false}
      />
      <img
        src={loseMsg}
        alt="lose-message"
        className={
          winStatus === false
            ? "result-message result-visible"
            : winStatus
            ? "result-message"
            : "result-message"
        }
        id="lose-message"
        draggable={false}
      />
      <div
        className={settingScreen || resultStatus ? "game-bg blur" : "game-bg"}
        id="image-prevent"
        style={{
          backgroundImage: !theme
            ? `${maxWidth700 ? `url(${darkTabletBg})` : `url(${darkBg})`}`
            : `url(${lightBg})`,
          animation:
            modeSwitch && !modeRenderEffect
              ? "fadeOut 600ms ease-in-out 1"
              : !modeSwitch && modeRenderEffect
              ? "fadeOut 600ms ease-in-out 1"
              : "fadeIn 2s ease-in-out 1",
        }}
      >
        {/* /////// header Start //////// */}
        <div
          className={modeToggle ? "game-header" : "game-header game-header-375"}
        >
          <div className="left-header flex">
            {/* Rules button */}
            <button
              className="icon"
              style={{ background: "transparent" }}
              onClick={handleRuleScreen}
              disabled={
                settingScreen || disable || !internetStatus || btnDisable
                  ? true
                  : false
              }
            >
              <img src={rules} alt="rules" className="icon" />
            </button>

            {/* Hotkeys button */}
            <button
              className="icon"
              style={{ background: "transparent" }}
              onClick={handleHotkeys}
              disabled={
                settingScreen || disable || !internetStatus || btnDisable
                  ? true
                  : false
              }
            >
              <img src={hotkey} alt="hotkey" className="icon" />
            </button>

            {/* Stats button */}
            <button
              className="icon"
              style={{ background: "transparent" }}
              onClick={handleStatsScreen}
              disabled={
                settingScreen || disable || !internetStatus || btnDisable
                  ? true
                  : false
              }
            >
              <img src={stats} alt="stats" className="icon" />
            </button>

            {/* theme changer */}
            <button
              className="toggle-btn theme-button flex"
              onClick={() => {
                currentToggle.play();
                setTheme(!theme);
              }}
              disabled={settingScreen || btnDisable ? true : false}
            >
              <div
                className="btn-circle theme-circle flex"
                style={{ left: !theme ? "-3px " : "51%" }}
              >
                {!theme ? (
                  <img
                    src={whiteMoon}
                    alt="dark-theme"
                    className="theme-inside-icon"
                  />
                ) : (
                  <img
                    src={whiteSun}
                    alt="dark-theme"
                    className="theme-inside-icon"
                  />
                )}
              </div>
              <img src={moon} alt="dark-theme" className="theme-inside-icon" />
              <img src={sun} alt="dark-theme" className="theme-inside-icon" />
            </button>
          </div>

          <div className="right-header flex">
            {/* user profile view only real mode */}
            {!modeSwitch ? (
              <div className="user-profile flex">
                <button
                  className="user-money flex"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => handleTransactionPage()}
                  disabled={
                    settingScreen || disable || !internetStatus || btnDisable
                      ? true
                      : false
                  }
                  data-tooltip-id={"history"}
                >
                  <img src={bidCoin} alt="coins" className="coin-icon" />
                  {amountLoader ? (
                    <div
                      className="amount-loader loader"
                      style={{ borderColor: !theme ? "#26D4FF" : "#000" }}
                    ></div>
                  ) : (
                    <p className="user-coin-value">
                      {Number(creditPoints).toFixed(2)}
                    </p>
                  )}
                </button>

                {/* Add token btn */}
                <button
                  className="add-token-btn flex"
                  onClick={handleAddToken}
                  disabled={
                    settingScreen || disable || !internetStatus || btnDisable
                  }
                >
                  <img src={addIcon} alt="add-token" className="add-icon" />
                  <p className="add-token-icon-text">Deposit token</p>
                </button>
              </div>
            ) : null}

            <button
              className="icon"
              style={{ backgroundColor: "transparent", position: "relative" }}
              onClick={() => handleGameWallet()}
              disabled={
                settingScreen || disable || !internetStatus || btnDisable
                  ? true
                  : false
              }
            >
              <img src={wallet} alt="wallet" className="icon" />
            </button>

            {/* Drop Down list of wallets */}
            <ul
              className={
                dropDownWallet
                  ? `drop-down-list flex max-height`
                  : "drop-down-list flex"
              }
            >
              <li
                className="drop-down-item flex"
                onClick={() => {
                  handleGameWallet("nami");
                  if (!activeWallet) {
                    setActiveWallet("nami");
                  }
                }}
                style={{
                  backgroundColor:
                    activeWallet === "nami" && theme
                      ? "#c3bbbb"
                      : activeWallet === "nami" && !theme
                      ? "#201f1f"
                      : null,
                }}
              >
                <img
                  src={namiWallet}
                  alt="nami-wallet"
                  className="wallet-icon-name"
                />
                <p className="wallet-name">Nami</p>
              </li>
              {/* <li
                className="drop-down-item flex"
                onClick={() => {
                  handleGameWallet("eternl");
                  if (!activeWallet) {
                    setActiveWallet("eternl");
                  }
                }}
                style={{
                  backgroundColor:
                    activeWallet === "eternl" && theme
                      ? "#c3bbbb"
                      : activeWallet === "eternl" && !theme
                      ? "#201f1f"
                      : null,
                }}
              >
                <img
                  src={eternlWallet}
                  alt="nami-wallet"
                  className="wallet-icon-name"
                />
                <p className="wallet-name">Eternl</p>
              </li> */}
              {/* <li
                className="drop-down-item flex"
                onClick={() => {
                  handleGameWallet("flint");
                  if (!activeWallet) {
                    setActiveWallet("flint");
                  }
                }}
                style={{
                  backgroundColor:
                    activeWallet === "flint" && theme
                      ? "#c3bbbb"
                      : activeWallet === "flint" && !theme
                      ? "#201f1f"
                      : null,
                }}
              >
                <img
                  src={flintWallet}
                  alt="flint-wallet"
                  className="wallet-icon-name"
                />
                <p className="wallet-name">Flint</p>
              </li> */}

              {walletAdd && (
                <>
                  <div className="separate-list-item"></div>
                  <li
                    className="drop-down-item flex"
                    onClick={() => {
                      setUtxos([]);
                      handleGameWallet("disconnect");
                      setActiveWallet(false);
                    }}
                  >
                    <img
                      src={user}
                      alt="nami-wallet"
                      className={
                        !theme ? "wallet-icon-name user" : "wallet-icon-name"
                      }
                    />
                    <p className="wallet-name">Logout</p>
                  </li>
                </>
              )}
            </ul>

            {maxWidth700 || maxHeight700 ? (
              <button
                className="icon ham-burger"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  audio?.play();
                  setDropDownWallet(false);
                  setSettingScreen(true);
                }}
                disabled={
                  settingScreen || disable || btnDisable || !internetStatus
                }
              >
                <img
                  src={hamBurger}
                  alt="ham-burger"
                  className="icon ham-burger"
                />
              </button>
            ) : (
              <button
                className="icon"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  audio?.play();
                  setDropDownWallet(false);
                  setSettingScreen(true);
                }}
                disabled={
                  settingScreen || disable || !internetStatus || btnDisable
                }
              >
                <img src={setting} alt="setting" className="icon" />
              </button>
            )}
          </div>
        </div>

        {/* /////// header End //////// */}
        {!ruleScreen &&
        !hotkeyScreen &&
        !dashBoardScreen &&
        !statsScreen &&
        !leaderBoardScreen &&
        !addTokenScreen &&
        !redeemTokenScreen &&
        !termsCondition &&
        !transactionScreen &&
        !networkScreen ? (
          <div
            style={{
              animation: modeSwitch
                ? "fadeIn 2s ease-in-out 1"
                : "fadeIn 2s ease-in-out 1",
            }}
            className="game-container-wrapper flex"
          >
            {/* /////// Game Start //////// */}
            <div className="game-container flex">
              {/* advertising */}
              <a
                href={
                  getImage?.link ? getImage?.link : "https://riskycoinflip.com"
                }
                target="_blank"
                rel="noopener noreferrer"
                className={settingScreen ? "add-box disable-link" : "add-box"}
              >
                {imageAvailable ? (
                  <img
                    src={getImage?.image}
                    alt="advertising-banner"
                    className="add-banner"
                  />
                ) : (
                  <img
                    src={advertisingImageStatic}
                    alt="advertising-banner"
                    className="add-banner"
                  />
                )}
                {/* <img
                  src={
                    getImage ? getImage :  advertisingImageStatic
                  }
                  alt="advertising-banner"
                  className="add-banner"
                  id="image-ad"
                /> */}
              </a>

              {/* win lose balance box */}
              <div className="win-lose-balance-box flex">
                <p className="wlb-txt">
                  Total Win : {totalWin.toString().padStart(2, "0")}
                </p>
                <p className="wlb-txt">
                  Total Lose : {totalLose.toString().padStart(2, "0")}
                </p>
                <p className="wlb-txt">
                  Ada Won :{" "}
                  {Number(totalWinAmount.toString().padStart(2, "0")).toFixed(
                    2
                  )}
                </p>
              </div>

              {/* coin flip box */}
              <div className="coin-flip-box flex">
                <div className="streak-multiplier-box flex">
                  <div className="streak-box flex">
                    <p className="streak-multiplier-txt">Win Streak</p>
                    <p className="streak-multiplier-txt">
                      {modeSwitch
                        ? streak.toString().padStart(2, "0")
                        : totalStreak.toString().padStart(2, "0")}
                    </p>
                  </div>
                  <div className="multiplier-box flex">
                    <p className="streak-multiplier-txt">Multiplier</p>
                    <p className="streak-multiplier-txt multiplier-value">x2</p>
                  </div>
                </div>

                {/* coin */}
                {coinFace === "tail" ? (
                  <div
                    className={
                      result === "tail"
                        ? "coin animate-tail2"
                        : result === "head"
                        ? "coin animate-head2"
                        : "coin"
                    }
                  >
                    <div className="head head-2"></div>
                    <div className="tail tail-2"></div>
                  </div>
                ) : (
                  <div
                    className={
                      result === "tail"
                        ? "coin animate-tail"
                        : result === "head"
                        ? "coin animate-head"
                        : "coin"
                    }
                  >
                    <div className="head"></div>
                    <div className="tail"></div>
                  </div>
                )}

                <div className="inside-1-coin-flip-box"></div>
                <div className="inside-2-coin-flip-box"></div>
                <div className="inside-3-coin-flip-box"></div>
                <div className="border-left"></div>
                <div className="border-top"></div>
                <div className="border-right"></div>
                <div className="inside-background"></div>
                <img src={leftGlass} alt="left-glass" className="left-glass" />
                <img
                  src={middleGlass}
                  alt="middle-glass"
                  className="middle-glass"
                />
                <img
                  src={rightGlass}
                  alt="right-glass"
                  className="right-glass"
                />
              </div>

              {/* result box */}
              <div className="result-box-bg  flex">
                <div className="result-cover-box flex">
                  <div className="wrapper-result-container flex">
                    <div
                      className="result-container flex"
                      style={{
                        transform: `translateX(${-resultWidth}%)`,
                      }}
                    >
                      {resultImages.map((item, index) => (
                        <img
                          src={item}
                          alt="result"
                          className={`result-coin`}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* bid box */}
              <div className="bid-box-bg coin-flip-box flex">
                <div className="bid-container flex">
                  {/* min max box */}
                  <div className="min-max-box flex">
                    <img
                      src={min}
                      alt="min-button"
                      className="bid-btn"
                      onClick={handleMinBid}
                    />
                    <img
                      src={max}
                      alt="max-button"
                      className="bid-btn"
                      onClick={handleMaxBid}
                    />
                  </div>

                  {/* bid increase and decrease box */}
                  <div className="bid-increase-decrease-box flex">
                    <img
                      src={minus}
                      alt="minus button"
                      className="bid-btn position-set"
                      onClick={handleDecreaseBid}
                    />
                    <p className="bid-value">{bidValue.toFixed(2)}</p>
                    <img
                      src={plus}
                      alt="plus button"
                      className="bid-btn position-set"
                      onClick={handleIncreaseBid}
                    />
                  </div>

                  {/* half and 2x box */}
                  <div className="half-twoX-box flex">
                    <img
                      src={half}
                      alt="half-button"
                      className="bid-btn"
                      onClick={handleHalfBid}
                    />
                    <img
                      src={twoX}
                      alt="2x-button"
                      className="bid-btn"
                      onClick={handle2xBid}
                    />
                  </div>
                </div>

                <div className="inside-1-coin-flip-box bid-box-inside-1"></div>
                <div className="inside-2-coin-flip-box bid-box-inside-2"></div>
                <div className="inside-3-coin-flip-box bid-box-inside-3"></div>
                <div className="border-left bid-box-border-left"></div>
                <div className="border-top bid-box-border-top"></div>
                <div className="border-right bid-box-border-right"></div>
                <div className="inside-background bid-box-inside-color"></div>
                <img src={leftGlass} alt="left-glass" className="left-glass" />
                <img
                  src={middleGlass}
                  alt="middle-glass"
                  className="middle-glass"
                />
                <img
                  src={rightGlass}
                  alt="right-glass"
                  className="right-glass"
                />
              </div>

              {/* bet btns head & tail */}
              <div className="bet-btns-box flex">
                <BetBtn
                  imgSrc={!theme ? betHead : lightBetBtnBg}
                  secondImg={head}
                  onClick={() => handleBetButtons("head")}
                  btnDisable={btnDisable || settingScreen ? true : false}
                  btnValue={"bet head"}
                  theme={theme}
                />
                <BetBtn
                  imgSrc={!theme ? betTail : lightBetBtnBg}
                  secondImg={tail}
                  onClick={() => handleBetButtons("tail")}
                  btnDisable={btnDisable || settingScreen ? true : false}
                  btnValue={"bet tail"}
                  theme={theme}
                />
              </div>

              {/* /////// footer Start //////// */}
              <div className="footer-bg flex">
                <div className="footer-txt-box flex">
                  <p className="footer-txt">
                    Credits :{" "}
                    {Number(creditPoints)
                      .toFixed(2)
                      .toString()
                      .padStart(2, "0")}
                  </p>

                  <div className="change-game-mode flex">
                    <p className="footer-txt">Real</p>
                    <button
                      className="toggle-btn footer-btn"
                      style={{
                        border: modeToggle
                          ? "1.5px solid #3F0000"
                          : "0.621px solid #103F00",
                        boxShadow: modeToggle
                          ? "box-shadow: 0px 2.68884px 2.01663px 0px rgba(0, 0, 0, 0.15) inset"
                          : "0px 2.68884px 2.01663px 0px rgba(0, 0, 0, 0.15) inset",
                      }}
                      onClick={() => {
                        if (internetStatus === false) {
                          toast("Please Check Your Internet Connection!", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: !theme ? "dark" : "light",
                          });
                        } else {
                          currentToggle.play();
                          setModeToggle(!modeToggle);
                        }
                      }}
                      disabled={settingScreen || btnDisable}
                      data-tooltip-id={modeSwitch ? "real-mode" : "fun-mode"}
                    >
                      <div
                        className="btn-circle flex footer-circle"
                        style={{
                          left: modeToggle ? "-3px " : "51%",
                          background: modeToggle
                            ? `linear-gradient(0deg, #58F621 0%, #BA0505 0.01%, #FF6767 100%)`
                            : "linear-gradient(0deg, #21A9F6 0%, #1EBA05 0.01%, #85FF67 100%)",
                          border: modeToggle
                            ? `2px solid #620505`
                            : `1.008px solid #126205`,
                        }}
                      >
                        {modeToggle ? (
                          <>
                            <div className="horizontal-line"></div>
                            <div className="horizontal-line second"></div>
                          </>
                        ) : (
                          <img
                            src={rightArrow}
                            alt="real-mode"
                            className="right-arrow"
                          />
                        )}
                      </div>
                    </button>
                    <p className="footer-txt">{modeToggle ? "off" : "on"}</p>
                  </div>
                  <p className="footer-txt">
                    Flip won :{" "}
                    {winAmount.toFixed(2).toString().padStart(2, "0")}
                  </p>
                </div>
              </div>
              {/* /////// footer end //////// */}
            </div>
            {/* /////// Game End //////// */}
          </div>
        ) : ruleScreen ? (
          <div className="flex heightWidth">
            <Rules
              setRuleScreen={setRuleScreen}
              setRuleEffect={setRuleEffect}
              ruleEffect={ruleEffect}
              audio={audio}
            />
          </div>
        ) : hotkeyScreen ? (
          <div className="flex heightWidth">
            <HotKeys
              hotkeyToggle={hotkeyToggle}
              setHotkeyToggle={setHotkeyToggle}
              setHotkeyScreen={setHotkeyScreen}
              setHotKeyEffect={setHotKeyEffect}
              hotkeyEffect={hotkeyEffect}
              theme={theme}
              audio={audio}
              currentToggle={currentToggle}
            />
          </div>
        ) : statsScreen ? (
          <div className="flex heightWidth">
            <Stats
              setStatsScreen={setStatsScreen}
              userStats={userStats}
              statsEffect={statsEffect}
              setStatsEffect={setStatsEffect}
              audio={audio}
            />
          </div>
        ) : transactionScreen ? (
          <div className="flex heightWidth">
            <Transaction
              setTransactionScreen={setTransactionScreen}
              transactionEffect={transactionEffect}
              setTransactionEffect={setTransactionEffect}
              walletAdd={walletAdd}
              creditPoints={creditPoints}
              userToken={userToken}
              theme={theme}
              audio={audio}
            />
          </div>
        ) : dashBoardScreen ? (
          <div className="flex heightWidth">
            <Dashboard
              setSettingScreen={setSettingScreen}
              setDashBoardScreen={setDashBoardScreen}
              getDashboardData={getDashboardData}
              gameDashboardData={gameDashboardData}
              audio={audio}
            />
          </div>
        ) : leaderBoardScreen ? (
          <div className="flex heightWidth">
            <Leaderboard
              setSettingScreen={setSettingScreen}
              setLeaderBoardScreen={setLeaderBoardScreen}
              theme={theme}
              audio={audio}
            />
          </div>
        ) : termsCondition ? (
          <div className="flex heightWidth">
            <TermsCondition
              setSettingScreen={setSettingScreen}
              setTermsCondition={setTermsCondition}
              theme={theme}
              audio={audio}
            />
          </div>
        ) : addTokenScreen ? (
          <div className="flex heightWidth">
            <AddToken
              setSettingScreen={setSettingScreen}
              setAddTokenScreen={setAddTokenScreen}
              API={API}
              walletAdd={walletAdd}
              userToken={userToken}
              theme={theme}
              setCreditPoints={setCreditPoints}
              setAmountLoader={setAmountLoader}
              audio={audio}
              Utxos={Utxos}
              setUtxos={setUtxos}
              setDisable={setDisable}
              disable={disable}
              getTokenAPI={getTokenAPI}
              // setTransactionMessage={setTransactionMessage}
              activeWalletName={activeWalletName}
            />
          </div>
        ) : redeemTokenScreen ? (
          <div className="flex heightWidth">
            <RedeemToken
              setSettingScreen={setSettingScreen}
              setRedeemTokenScreen={setRedeemTokenScreen}
              creditPoints={creditPoints}
              userToken={userToken}
              walletAdd={walletAdd}
              theme={theme}
              setCreditPoints={setCreditPoints}
              setAmountLoader={setAmountLoader}
              audio={audio}
              setDisable={setDisable}
              disable={disable}
              getTokenAPI={getTokenAPI}
              activeWalletName={activeWalletName}
              API={API}
            />
          </div>
        ) : networkScreen ? (
          // handle Internet Connectivity
          <div className="internet-connectivity flex">
            <h1 className="network-heading">Network</h1>
            <div className="network-message-wrapper flex">
              {internetStatus ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // class="icon icon-tabler icon-tabler-access-point"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="#00ff00"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="net-icon"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12l0 .01"></path>
                    <path d="M14.828 9.172a4 4 0 0 1 0 5.656"></path>
                    <path d="M17.657 6.343a8 8 0 0 1 0 11.314"></path>
                    <path d="M9.168 14.828a4 4 0 0 1 0 -5.656"></path>
                    <path d="M6.337 17.657a8 8 0 0 1 0 -11.314"></path>
                  </svg>
                  <p className="internet-status-message">
                    Your internet connection has restored
                  </p>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // class="icon icon-tabler icon-tabler-access-point-off"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="#ff0000"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="net-icon"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 3l18 18"></path>
                    <path d="M14.828 9.172a4 4 0 0 1 1.172 2.828"></path>
                    <path d="M17.657 6.343a8 8 0 0 1 1.635 8.952"></path>
                    <path d="M9.168 14.828a4 4 0 0 1 0 -5.656"></path>
                    <path d="M6.337 17.657a8 8 0 0 1 0 -11.314"></path>
                  </svg>
                  <p className="internet-status-message">
                    Seems like your internet connection has lost
                  </p>
                </>
              )}
            </div>
          </div>
        ) : // handle Internet Connectivity
        null}
      </div>
      <Setting
        setWinStatus={setWinStatus}
        settingScreen={settingScreen}
        setSettingScreen={setSettingScreen}
        setDashBoardScreen={setDashBoardScreen}
        setLeaderBoardScreen={setLeaderBoardScreen}
        setTermsCondition={setTermsCondition}
        setRuleScreen={setRuleScreen}
        setHotkeyScreen={setHotkeyScreen}
        setStatsScreen={setStatsScreen}
        setTransactionScreen={setTransactionScreen}
        setAddTokenScreen={setAddTokenScreen}
        setRedeemTokenScreen={setRedeemTokenScreen}
        modeSwitch={modeSwitch}
        theme={theme}
        API={API}
        maxWidth700={maxWidth700}
        maxHeight700={maxHeight700}
        handleRuleScreen={handleRuleScreen}
        handleHotkeys={handleHotkeys}
        handleStatsScreen={handleStatsScreen}
        handleGameWallet={handleGameWallet}
        bgMusicRef={bgMusicRef}
        coinFlipRef={coinFlipRef}
        betBtnRef={betBtnRef}
        coinSound={coinSound}
        walletAdd={walletAdd}
        audio={audio}
        winSoundRef={winSoundRef}
        loseSoundRef={loseSoundRef}
        toogleSoundRef={toogleSoundRef}
        hotkeyToggle={hotkeyToggle}
        isSoundPause={isSoundPause}
        setIsSoundPause={setIsSoundPause}
        platform={platform}
      />
      {/* audios of win and lose message */}
      <audio src={winSound} ref={winSoundRef} />
      <audio src={loseSound} ref={loseSoundRef} />
      <Tooltip
        id="real-mode"
        place="bottom"
        variant={!theme ? "dark" : "light"}
      >
        Go To Real Mode
      </Tooltip>
      <Tooltip id="fun-mode" place="bottom" variant={!theme ? "dark" : "light"}>
        Go To Fun Mode
      </Tooltip>
      <Tooltip id="history" place="bottom" variant={!theme ? "dark" : "light"}>
        Transaction History
      </Tooltip>
    </>
  );
};

export default Game;
