import Axios from "axios";

const axiosBaseURL = Axios.create({
  // baseURL: "https://coinapi.riskycoinflip.com/api",
  baseURL: "https://betacoinapi.riskycoinflip.com/api",
});

// const URL = "https://coinapi.riskycoinflip.com";
const URL = "https://betacoinapi.riskycoinflip.com";

export { URL };
export default axiosBaseURL;
