import CryptoJS from "crypto-js";

export function decryption(data) {
  const secretKey = "c16d5adecfd9614d8bd4bf598b9e778a";
  const iv = "1b2d6c6e1aee25376413da6e0107f8a5";
  // Decrypt
  const bytes = CryptoJS.AES.decrypt(data, secretKey, { iv: iv });
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export function decryptionObj(data) {
  const secretKey = "c16d5adecfd9614d8bd4bf598b9e778a";
  const iv = "1b2d6c6e1aee25376413da6e0107f8a5";
  const bytes = CryptoJS.AES.decrypt(data, secretKey, { iv: iv });
  const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return originalText;
}

const data = decryption("U2FsdGVkX1/M4V3F168tZhRJWF4zk1xGtMSrgqBwuUY=");
//  console.log("data", data)
