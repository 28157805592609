import React, { useEffect, useState } from "react";
import "../styles/Transaction.css";
import Close from "../components/Close";
import TransactionHistory from "../components/TransactionHistory";
import { getTransactionHistory } from "../APIs/Transactions/GetAPI";
import { decryption } from "../Functions/Decrypted";
import { toast } from "react-toastify";

const Transaction = ({
  setTransactionScreen,
  transactionEffect,
  setTransactionEffect,
  walletAdd,
  creditPoints,
  userToken,
  theme,
  audio,
}) => {
  const [transactionHistory, setTransactionHistory] = useState([]);

  // console.log("creditPoints", creditPoints);

  // eslint-disable-next-line
  const point = creditPoints;

  useEffect(() => {
    if (transactionEffect) {
      return;
    }
    setTimeout(() => {
      setTransactionScreen(false);
    }, 800);
  }, [setTransactionScreen, transactionEffect]);

  useEffect(() => {
    if (!userToken) {
      return;
    }

    const getTransaction = async () => {
      const result = await getTransactionHistory(userToken);
      const encryptedTransactoinArray = result.map((item) => {
        if (item.encryptedData) {
          return [decryption(item.encryptedData), item.createdAt, item.status];
        }
        return false;
      });
      // console.log("encryptedTransactoinArray",encryptedTransactoinArray)
      setTransactionHistory(encryptedTransactoinArray.reverse());
    };

    getTransaction();
  }, [userToken]);

  const copyToClipboard = () => {
    const text = walletAdd;
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success("Copied!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: !theme ? "dark" : "light",
    });
  };

  return (
    <div
      className="wallet-container flex"
      style={{
        animation: transactionEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <h1 className="wallet-heading">Wallet</h1>

      <div className="wallet-details flex">
        <div className="wallet-detail-left-side">
          <h2 className="account-heading">my account</h2>
          <p className="wallet-address-id" onClick={() => copyToClipboard()}>
            id :{" "}
            {walletAdd
              ? walletAdd.slice(0, 4) +
                "..." +
                walletAdd.slice(walletAdd.length - 4, walletAdd.length)
              : "Wallet ID"}
          </p>
        </div>
        <div className="wallet-detail-right-side">
          <p className="wallet-balance-txt">
            {/* balance : <span className="wallet-balance-value">{Number.isInteger(point) === false ? point?.toFixed(2) : point}</span> */}
          </p>
        </div>
      </div>

      <div className="wallet-subheading-bg flex">
        <div className="flex wallet-subheading-box">
          <div className="wallet-subheading">Transaction hash</div>
          <div className="flex wallet-middle-wrapper">
            <div className="leaderboard-vertical-line"></div>
            <div className="wallet-subheading">Date</div>
            <div className="leaderboard-vertical-line"></div>
          </div>
          <div className="wallet-subheading amt-txt">Amount</div>
          <div className="leaderboard-vertical-line"></div>
          <div className="wallet-subheading amt-txt">Status</div>
        </div>
      </div>

      <div className="wallet-transaction-history-container flex">
        {transactionHistory.length ? (
          transactionHistory.map((item, index) => {
            if (!item) {
              return null;
            }
            const trans = JSON.parse(item[0]);
            // console.log("items", trans);
            return (
              <div
                key={index}
                className="wallet-user-bg-black flex"
                style={{ boxShadow: theme && "none" }}
              >
                <TransactionHistory
                  id={trans.hash}
                  date={item[1]}
                  amt={trans.count}
                  type={trans.Type}
                  status={item[2]}
                />
              </div>
            );
          })
        ) : (
          <h1 className="no-trasaction-found">No Transacton Found</h1>
        )}
      </div>
      <Close
        onClick={() => {
          audio.play();
          setTransactionEffect(false);
        }}
      />
    </div>
  );
};

export default Transaction;
