import { decryption } from "../Functions/Decrypted";
import axiosBaseURL from "./axiosConfig";

const verifyImage = async (url) => {
  try {
    const response = await fetch(url.image);
    if (response.ok) {
      return url;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const leaderBoard = async () => {
  try {
    const encryptResult = await axiosBaseURL.get("/leaderboard");
    const decryptResult = decryption(encryptResult.data.Leaderboard);
    return decryptResult;
  } catch (error) {
    console.log("Error occur get leaderboard api", error);
  }
};

export const getStats = async (token) => {
  try {
    const encryptResult = await axiosBaseURL.get("/get-Stats", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (encryptResult.data.Stats?.encryptedData) {
      const decryptResult = decryption(encryptResult.data.Stats.encryptedData);
      return decryptResult;
    }
    return false;
  } catch (error) {
    console.log("Error occur get stats api", error);
  }
};

export const getDashboard = async (token) => {
  try {
    const encryptResult = await axiosBaseURL.get("/getdashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (encryptResult.data?.Dashboard) {
      const decryptResult = decryption(
        encryptResult.data.Dashboard.encryptedData
      );
      return decryptResult;
    }
    return false;
  } catch (error) {
    console.log("Error occur get dashboard api message", error);
  }
};

// get Advertising image from admin panel
export const advertisingImageFromServer = async () => {
  try {
    const imageURL = await axiosBaseURL.get("/get_Adsimage");
    // console.log("Image URL", imageURL.data);
    if (imageURL.data.Success) {
      const resultAfterVerfication = await verifyImage(imageURL.data.Ads_Image);
      return resultAfterVerfication;
    }
    return null;
  } catch (error) {
    console.log("Error get image url", error);
  }
};

export const getInMaintenance = async () => {
  try {
    const res = await axiosBaseURL.get("/get_SiteMaintenanceData");

    if (res.data.Success) {
      console.log("res", res.data);
      return res.data.Sitedata.Ismaintenance;
    }
    return false;
  } catch (error) {
    console.log("Error **** in getInMaintenance api", error);
  }
};
