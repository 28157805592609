import React, { useEffect, useState } from "react";
import Game from "./Game";

import { Address } from "@emurgo/cardano-serialization-lib-asmjs";
// import { Address } from "@emurgo/cardano-serialization-lib-browser";
import { Buffer } from "buffer";
import {
  addStats,
  betADA,
  gameDashboard,
  loginRegister,
} from "../APIs/PostAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDashboard, getStats } from "../APIs/GetAPI";
import { getToken } from "../APIs/Transactions/GetAPI";
import { deductBet, addWinToken } from "../APIs/Transactions/PostAPI";

// const MULTIPLIER_AMOUNT = 2
const RealMode = ({
  setModeToggle,
  modeToggle,
  setTheme,
  theme,
  modeRenderEffect,
  modeSwitch,
  bgMusicRef,
  coinFlipRef,
  betBtnRef,
  toogleSoundRef,
  winSoundRef,
  loseSoundRef,
  internetStatus,
  advertisingImage,
}) => {
  const [streak, setStreak] = useState(0);
  const [creditPoints, setCreditPoints] = useState(0);
  const [walletAdd, setWalletAdd] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [totalWin, setTotalWin] = useState(0);
  const [totalLose, setTotalLose] = useState(0);
  const [totalStreak, setTotalStreak] = useState(0);
  const [isStreak, setIsStreak] = useState(false);
  const [userChoose, setUserChoose] = useState(null);
  const [winStatus, setWinStatus] = useState(null);
  const [coinFace, setCoinFace] = useState("head");
  const [gameDashboardData, setGameDashboardData] = useState({
    flipDone: 0,
    highestStreak: 0,
    mostWin: 0,
  });

  //
  const [btnDisable, setBtnDisable] = useState(false);
  const [winAmount, setWinAmount] = useState(0);
  const [totalWinAmount, setTotalWinAmount] = useState(0);
  const [result, setResult] = useState(null);
  const [trackResult, setTrackResult] = useState(null);
  const [bidValue, setBidValue] = useState(1);
  const [API, setAPI] = useState(null);
  const [toss, setToss] = useState(false);
  const [amountLoader, setAmountLoader] = useState(false);
  const [isSoundPause, setIsSoundPause] = useState(null);
  const [activeWalletName, setActiveWalletName] = useState("");
  const [resultStatus, setResultStatus] = useState(false);

  // state of get Stats
  const [userStats, setUserStats] = useState({
    losess: 0,
    win: 0,
    profit: 0,
  });

  const userLoginState = (result, walletName) => {
    const loginArray = [result.token, walletName];
    localStorage.setItem("login", JSON.stringify(loginArray));
    localStorage.setItem("loginDate", JSON.stringify(new Date().getDate()));
    localStorage.setItem("loginMonth", JSON.stringify(new Date().getMonth()));
  };

  // nami wallet connect
  const enableWallet = async (value) => {
    try {
      if (walletAdd && value === "disconnect") {
        localStorage.removeItem("login");
        localStorage.removeItem("loginDate");
        localStorage.removeItem("loginMonth");
        setUserToken("");
        setWalletAdd("");
        setCreditPoints(0);
        setUserStats({
          losess: 0,
          win: 0,
          profit: 0,
        });
        setGameDashboardData({
          flipDone: 0,
          highestStreak: 0,
          mostWin: 0,
        });
        toast.error("Logout Successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: !theme ? "dark" : "light",
        });
        return;
      }

      if (walletAdd && value === "nami") return;

      // if (walletAdd && value === "eternl") return;
      // if (walletAdd && value === "flint") return;

      // Check if the Nami Wallet extension is available
      if (!window.cardano || !window.cardano[value]) {
        // You can also provide a link to the Nami Wallet extension page
        if (value === "nami") {
          window.open(
            "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo?hl=en-US",
            "_blank"
          );
          // } else {
          //   window.open(
          //     "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
          //     "_blank"
          //   );
          // }
        }
        // else {
        //   window.open(
        //     "https://chromewebstore.google.com/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
        //     "_blank"
        //   );
        // }
        return;
      }
      setAmountLoader(true);
      const api = await window.cardano[value].enable();
      setAPI(api);
      let usedAddress;
      setActiveWalletName(value);
      if (value === "nami") {
        const raw = await api.getUsedAddresses();
        // console.log(raw);
        const rawFirst = raw[0];
        usedAddress = Address.from_bytes(
          Buffer.from(rawFirst, "hex")
        ).to_bech32();
      } else {
        const raw = await api.getChangeAddress();
        // console.log(raw);
        usedAddress = Address.from_bytes(Buffer.from(raw, "hex")).to_bech32();
      }

      // When user didn't logout
      if (JSON.parse(localStorage.getItem("login"))) {
        const lastLoginDate = JSON.parse(localStorage.getItem("loginDate"));
        const lastLoginMonth = JSON.parse(localStorage.getItem("loginMonth"));
        if (lastLoginMonth === new Date().getMonth()) {
          if (lastLoginDate < new Date().getDate()) {
            // console.log("Date changed");
            localStorage.removeItem("login");
            localStorage.removeItem("loginDate");
            localStorage.removeItem("loginMonth");
            setAmountLoader(false);
            return;
          }

          // user login/register api
          const result = await loginRegister(usedAddress);
          if (result.Success) {
            setWalletAdd(usedAddress);
            setUserToken(result.token);
            userLoginState(result, value);
            toast.success("Login Successfully!", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: !theme ? "dark" : "light",
            });
          }
          return;
        } else {
          // console.log("Month changed");
          localStorage.removeItem("login");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("loginMonth");
          setAmountLoader(false);
          return;
        }
      }

      // user login/register api
      const result = await loginRegister(usedAddress);
      if (result.Success) {
        setWalletAdd(usedAddress);
        setUserToken(result.token);
        userLoginState(result, value);
        toast.success("Login Successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: !theme ? "dark" : "light",
        });
        setAmountLoader(false);
      }
    } catch (error) {
      // console.log("Error", error.message);
      if (error.message === "no account set") {
        toast.error(
          // "Switch to the Eternl browser extension and activate a dApp account!",
          "Switch to the Flint browser extension and activate a dApp account!",
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: !theme ? "dark" : "light",
          }
        );
      }
      setAmountLoader(false);
    }
  };

  useEffect(() => {
    const retArray = JSON.parse(localStorage.getItem("login"));
    if (retArray) {
      enableWallet(retArray[1]);
    }
    // eslint-disable-next-line
  }, []);

  // getStat api call
  const getUserStats = async () => {
    if (!internetStatus) {
      return;
    }
    const result = await getStats(userToken);
    if (result) {
      const resultArr = result.split(",");
      setUserStats({
        losess: resultArr[0],
        win: resultArr[1],
        profit: resultArr[2],
      });
    } else {
      setUserStats({
        losess: 0,
        win: 0,
        profit: 0,
      });
    }
  };

  // call get stats api when token update.
  useEffect(() => {
    if (userToken && internetStatus) {
      getUserStats();
    }
    // eslint-disable-next-line
  }, [userToken, internetStatus]);

  // Add Stats
  const postStats = async () => {
    const data = `${userStats.losess},${userStats.win},${Number(
      userStats.profit
    ).toFixed(2)}`;
    addStats(userToken, data);
  };

  useEffect(() => {
    if (userToken && internetStatus) {
      if (toss) {
        if (parseInt(userStats.losess) === 0 && parseInt(userStats.win) === 0) {
          return;
        }
        postStats();
        setTimeout(() => {
          gameDashboardUpdate(gameDashboardData.highestStreak);
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, [
    userStats,
    userToken,
    toss,
    gameDashboardData.highestStreak,
    internetStatus,
  ]);

  const winTokenAPI = async (amt) => {
    await addWinToken(userToken, amt);
    getTokenAPI();
  };

  useEffect(() => {
    const screenChange = (event) => {
      if (document.visibilityState === "visible") {
        setTimeout(() => {
          setResultStatus(false);
        }, 3000);
      }
    };
    document.addEventListener("visibilitychange", screenChange);

    return () => document.removeEventListener("visibilitychange", screenChange);
  }, []);

  // handleCoinToss
  // const coinToss = (value, audio) => {
  //   betADA(bidValue);
  //   const random = Math.floor(Math.random() * 1000 + 1);
  //   if (random % 2 === 0) {
  //     setResult("tail");
  //     setTimeout(() => {
  //       audio.play();
  //     }, 100);
  //     setTrackResult("tail");
  //     if (value === "tail") {
  //       setTimeout(() => {
  //         setUserStats((prev) => ({
  //           ...prev,
  //           win: parseInt(prev.win) + 1,
  //           profit: Number(prev.profit) + bidValue,
  //         }));
  //         setTotalWin((prev) => prev + 1);
  //         setIsStreak(true);
  //         setWinAmount(bidValue * 2);
  //         setTotalWinAmount((prev) => prev + bidValue);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(true);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("tail");
  //         setToss(true);
  //         winTokenAPI(bidValue * 2);
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             winSoundRef.current.pause();
  //             setIsSoundPause("win");
  //           } else {
  //             winSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         setTimeout(() => {
  //           setToss(false);
  //         }, 2500);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     } else {
  //       setTimeout(() => {
  //         setUserStats((prev) => ({
  //           ...prev,
  //           losess: parseInt(prev.losess) + 1,
  //           profit: Number(prev.profit) + 0,
  //         }));
  //         setTotalLose((prev) => prev + 1);
  //         setIsStreak(false);
  //         setWinAmount(0);
  //         setTotalWinAmount((prev) => prev + 0);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(false);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("tail");
  //         setToss(true);
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             loseSoundRef.current.pause();
  //             setIsSoundPause("lose");
  //           } else {
  //             loseSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         setTimeout(() => {
  //           setToss(false);
  //         }, 2500);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     }
  //   } else {
  //     setResult("head");
  //     setTimeout(() => {
  //       audio.play();
  //     }, 100);
  //     setTrackResult("head");
  //     if (value === "head") {
  //       setTimeout(() => {
  //         setUserStats((prev) => ({
  //           ...prev,
  //           win: parseInt(prev.win) + 1,
  //           profit: Number(prev.profit) + bidValue,
  //         }));
  //         setIsStreak(true);
  //         setTotalWin((prev) => prev + 1);
  //         setWinAmount(bidValue * 2);
  //         setTotalWinAmount((prev) => prev + bidValue);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(true);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("head");
  //         setToss(true);
  //         winTokenAPI(bidValue * 2);
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             winSoundRef.current.pause();
  //             setIsSoundPause("win");
  //           } else {
  //             winSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         setTimeout(() => {
  //           setToss(false);
  //         }, 2500);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     } else {
  //       setTimeout(() => {
  //         setUserStats((prev) => ({
  //           ...prev,
  //           losess: parseInt(prev.losess) + 1,
  //           profit: Number(prev.profit) + 0,
  //         }));
  //         setTotalLose((prev) => prev + 1);
  //         setWinAmount(0);
  //         setIsStreak(false);
  //         setTotalWinAmount((prev) => prev + 0);
  //         if (document.hidden || document.webkitHidden) {
  //           setWinStatus(null);
  //           setResultStatus(true);
  //           setBtnDisable(false);
  //         } else {
  //           setWinStatus(false);
  //           setResultStatus(true);
  //         }
  //         setResult(null);
  //         setUserChoose(value);
  //         setCoinFace("head");
  //         setToss(true);
  //         setTimeout(() => {
  //           if (document.hidden || document.webkitHidden) {
  //             loseSoundRef.current.pause();
  //             setIsSoundPause("lose");
  //           } else {
  //             loseSoundRef.current.play();
  //             setIsSoundPause(null);
  //           }
  //         }, 300);
  //         setTimeout(() => {
  //           setToss(false);
  //         }, 2500);
  //         if (!document.hidden || !document.webkitHidden) {
  //           setTimeout(() => {
  //             setWinStatus(null);
  //             setResultStatus(false);
  //             setBtnDisable(false);
  //           }, 2500);
  //         }
  //       }, 3400);
  //     }
  //   }
  // };

  const coinToss = (value, audio) => {
    betADA(bidValue);
    const random = Math.floor(Math.random() * 10 + 1);
    const win = random <= 3;
    const result = win ? value : value === "head" ? "tail" : "head";
    setResult(result);
    setTimeout(() => {
      audio.play();
    }, 100);
    setTrackResult(result);
    if (value === result) {
      setTimeout(() => {
        setUserStats((prev) => ({
          ...prev,
          win: parseInt(prev.win) + 1,
          profit: Number(prev.profit) + bidValue,
        }));
        setTotalWin((prev) => prev + 1);
        setIsStreak(true);
        setWinAmount(bidValue * 2);
        setTotalWinAmount((prev) => prev + bidValue);
        if (document.hidden || document.webkitHidden) {
          setWinStatus(null);
          setResultStatus(true);
          setBtnDisable(false);
        } else {
          setWinStatus(true);
          setResultStatus(true);
        }
        setResult(null);
        setUserChoose(value);
        setCoinFace(result);
        setToss(true);
        winTokenAPI(bidValue * 2);
        setTimeout(() => {
          if (document.hidden || document.webkitHidden) {
            winSoundRef.current.pause();
            setIsSoundPause("win");
          } else {
            winSoundRef.current.play();
            setIsSoundPause(null);
          }
        }, 300);
        setTimeout(() => {
          setToss(false);
        }, 2500);
        if (!document.hidden || !document.webkitHidden) {
          setTimeout(() => {
            setWinStatus(null);
            setResultStatus(false);
            setBtnDisable(false);
          }, 2500);
        }
      }, 3400);
    } else {
      setTimeout(() => {
        setUserStats((prev) => ({
          ...prev,
          losess: parseInt(prev.losess) + 1,
          profit: Number(prev.profit) + 0,
        }));
        setTotalLose((prev) => prev + 1);
        setIsStreak(false);
        setWinAmount(0);
        setTotalWinAmount((prev) => prev + 0);
        if (document.hidden || document.webkitHidden) {
          setWinStatus(null);
          setResultStatus(true);
          setBtnDisable(false);
        } else {
          setWinStatus(false);
          setResultStatus(true);
        }
        setResult(null);
        setUserChoose(value);
        setCoinFace(result);
        setToss(true);
        setTimeout(() => {
          if (document.hidden || document.webkitHidden) {
            loseSoundRef.current.pause();
            setIsSoundPause("lose");
          } else {
            loseSoundRef.current.play();
            setIsSoundPause(null);
          }
        }, 300);
        setTimeout(() => {
          setToss(false);
        }, 2500);
        if (!document.hidden || !document.webkitHidden) {
          setTimeout(() => {
            setWinStatus(null);
            setResultStatus(false);
            setBtnDisable(false);
          }, 2500);
        }
      }, 3400);
    }
  };

  // handleBetCoin
  const handleBet = async (value, audio) => {
    if (userToken) {
      if (creditPoints >= bidValue) {
        setCreditPoints((prev) => prev - bidValue);
        setBtnDisable(true);
        const result = await deductBet(userToken, bidValue);
        if (result.Success) {
          coinToss(value, audio);
          return;
        }
        setBtnDisable(false);
      } else {
        toast("Insufficient funds!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: !theme ? "dark" : "light",
        });
      }
    } else {
      toast("You must login first!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
    }
  };

  // normal streak manage
  useEffect(() => {
    if (isStreak) {
      setStreak((prev) => prev + 1);
      return;
    }
    setStreak(0);
  }, [totalLose, totalWin, isStreak]);

  // most streak
  useEffect(() => {
    if (streak > totalStreak) {
      setTotalStreak(streak);
    }
  }, [streak, totalStreak]);

  useEffect(() => {
    if (totalStreak >= parseInt(gameDashboardData.highestStreak) && userToken) {
      setGameDashboardData((prev) => ({
        ...prev,
        highestStreak: Number(prev.highestStreak) + 1,
      }));
    }
    // eslint-disable-next-line
  }, [totalStreak, userToken]);

  // get dashboard api
  const getDashboardData = async () => {
    if (!userToken || !internetStatus) {
      return;
    }
    const resultStr = await getDashboard(userToken);
    // console.log("Game dashboard get Data", resultStr);
    if (resultStr) {
      const result = resultStr.split(",");
      setGameDashboardData({
        flipDone: result[0],
        highestStreak: result[1],
        mostWin: result[2],
      });
    } else {
      setGameDashboardData({
        flipDone: 0,
        highestStreak: 0,
        mostWin: 0,
      });
    }
  };

  useEffect(() => {
    if (userToken && internetStatus) {
      getDashboardData();
    }
    // eslint-disable-next-line
  }, [userToken, internetStatus]);

  const gameDashboardUpdate = async (mostStreak) => {
    const data = `${gameDashboardData.flipDone},${mostStreak},${Number(
      userStats.profit
    ).toFixed(2)}`;
    // const data = `0,0,0.00`;
    // console.log("Add Dashboard Data....////", data);
    const flipCount = await gameDashboard(userToken, data);
    setGameDashboardData((prev) => ({
      ...prev,
      flipDone: flipCount,
    }));
  };

  const getTokenAPI = async () => {
    const result = await getToken(userToken);
    // console.log("get token", result);
    if (result) {
      setCreditPoints(result.count);
      setAmountLoader(false);
    } else {
      setCreditPoints(0);
      setAmountLoader(false);
    }
  };

  // get Token api
  useEffect(() => {
    if (!internetStatus || !userToken) {
      setCreditPoints(0);
      return;
    }

    const interval = setInterval(() => {
      getTokenAPI();
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [userToken, internetStatus]);

  return (
    <>
      <Game
        modeToggle={modeToggle}
        setModeToggle={setModeToggle}
        streak={streak}
        creditPoints={creditPoints}
        handleNamiWallet={enableWallet}
        walletAdd={walletAdd}
        totalWin={totalWin}
        totalLose={totalLose}
        totalStreak={totalStreak}
        bidValue={bidValue}
        setBidValue={setBidValue}
        totalWinAmount={totalWinAmount}
        result={result}
        trackResult={trackResult}
        btnDisable={btnDisable}
        winAmount={winAmount}
        userChoose={userChoose}
        handleBet={handleBet}
        setUserChoose={setUserChoose}
        winStatus={winStatus}
        setWinStatus={setWinStatus}
        coinFace={coinFace}
        getDashboardData={getDashboardData}
        gameDashboardData={gameDashboardData}
        theme={theme}
        setTheme={setTheme}
        userStats={userStats}
        modeRenderEffect={modeRenderEffect}
        modeSwitch={modeSwitch}
        API={API}
        userToken={userToken}
        setCreditPoints={setCreditPoints}
        getTokenAPI={getTokenAPI}
        amountLoader={amountLoader}
        setAmountLoader={setAmountLoader}
        bgMusicRef={bgMusicRef}
        coinFlipRef={coinFlipRef}
        betBtnRef={betBtnRef}
        toogleSoundRef={toogleSoundRef}
        winSoundRef={winSoundRef}
        loseSoundRef={loseSoundRef}
        internetStatus={internetStatus}
        isSoundPause={isSoundPause}
        setIsSoundPause={setIsSoundPause}
        activeWalletName={activeWalletName}
        resultStatus={resultStatus}
        advertisingImage={advertisingImage}
      />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={!theme ? "dark" : "light"}
      />
    </>
  );
};

export default RealMode;
