import React, { useEffect, useState } from "react";
import "../styles/TokenBox.css";
import minus from "../assets/minus.png";
import plus from "../assets/plus.png";
import Close from "../components/Close";
import {
  addAmount,
  addToken,
  // redeemToken,
  redeemTokenRequest,
} from "../APIs/Transactions/PostAPI";
import {
  Address,
  BigNum,
  LinearFee,
  Transaction,
  TransactionBuilder,
  TransactionBuilderConfigBuilder,
  TransactionOutput,
  TransactionUnspentOutputs,
  TransactionWitnessSet,
  Value,
} from "@emurgo/cardano-serialization-lib-asmjs";
// import {
//   Address,
//   BigNum,
//   LinearFee,
//   Transaction,
//   TransactionBuilder,
//   TransactionBuilderConfigBuilder,
//   TransactionOutput,
//   TransactionUnspentOutputs,
//   TransactionWitnessSet,
//   Value,
// } from "@emurgo/cardano-serialization-lib-browser";
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import {
  fetchTransactionDetails,
  fetchedLovelace,
} from "../APIs/Transactions/BlockFrost";
// import axios from "axios";
import { getTransactionHistory } from "../APIs/Transactions/GetAPI";
import { decryption } from "../Functions/Decrypted";
// import { localStorage } from "localStorage"; // Import localStorage

const protocolParams = {
  linearFee: {
    minFeeA: "44",
    minFeeB: "155381",
  },
  minUtxo: "34482",
  poolDeposit: "500000000",
  keyDeposit: "2000000",
  maxValSize: 5000,
  maxTxSize: 16384,
  // maxValSize: 4000,
  // maxTxSize: 8000,
  priceMem: 0.0577,
  priceStep: 0.0000721,
  coinsPerUtxoWord: "34482",
};

const TokenBox = ({
  heading,
  btnSrc,
  onClick,
  theme,
  API,
  walletAdd,
  userToken,
  creditPoints,
  audio,
  Utxos,
  // setUtxos,
  setDisable,
  disable,
  setTransactionMessage,
  getTokenAPI,
  activeWalletName,
  clickOnAddButton,
  setClickonAddButton,
  getUtxos,
  communityWalletAdd,
}) => {
  let checkUtxosArrayBalance = 0;
  const [tokenAmount, setTokenAmount] = useState(1);
  const [transactionProgress, setTransactionProgress] = useState(false);
  const [transactionMsg, setTransactionMsg] = useState(false);
  const [modalMsg, setModalMsg] = useState(false);
  const [mainBtnDisable, setMainBtnDisable] = useState(false);
  const [hashStatus, setHashStatus] = useState(false);
  const [transactionStart, setTransactionStart] = useState(false);
  const [hashId, setHashId] = useState(null);
  const [result, setResult] = useState(null);
  const [amountResult, setAmountResult] = useState(null);

  const initTransactionBuilder = async () => {
    const txBuilder = TransactionBuilder.new(
      TransactionBuilderConfigBuilder.new()
        .fee_algo(
          LinearFee.new(
            BigNum.from_str(protocolParams.linearFee.minFeeA),
            BigNum.from_str(protocolParams.linearFee.minFeeB)
          )
        )
        .pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
        .key_deposit(BigNum.from_str(protocolParams.keyDeposit))
        .coins_per_utxo_word(
          BigNum.from_str(protocolParams.coinsPerUtxoWord || "4310")
        )
        .max_value_size(protocolParams.maxValSize ?? "5000", 10)
        .max_tx_size(protocolParams.maxTxSize)
        // .prefer_pure_change(true)
        .build()
    );
    return txBuilder;
  };

  const getTxUnspentOutputs = async () => {
    let txOutputs = TransactionUnspentOutputs.new();
    // console.log("utxos in transaction..../////", Utxos);
    for (const utxo of Utxos) {
      txOutputs.add(utxo.TransactionUnspentOutput);
    }
    return txOutputs;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getTokenAPI();
    }, 5000);

    return () => clearInterval(interval);
  }, [getTokenAPI]);

  useEffect(() => {
    // Check if hashStatus is truthy and result is not obtained yet
    if (hashStatus && result === null && amountResult) {
      const id = setInterval(async () => {
        try {
          const fetchedResult = await fetchTransactionDetails(hashId);
          // console.log("Result......//////", fetchedResult);
          if (fetchedResult === "Payment required") {
            setResult(fetchedResult);
            setHashStatus(false);
            clearInterval(id);
            getUtxos();
            setTransactionMsg(true);
            setModalMsg("Payment required");
            setDisable(false);
            setTransactionStart(false);
            return;
          }
          // If the desired result is obtained, update state variables to stop the useEffect
          if (fetchedResult) {
            setResult(fetchedResult);
            setHashStatus(false);
            const obj = {
              hash: hashId,
              count: `${tokenAmount}`,
            };

            // Add hash
            const result = await addToken(userToken, obj);
            // console.log("Result add token api........./////", result);
            if (result) {
              clearInterval(id);
              getTokenAPI();
              getUtxos();
              setTransactionMsg(true);
              setModalMsg(true);
              setDisable(false);
              setTransactionStart(false);
              return;
            }
            clearInterval(id);
            getUtxos();
            setTransactionMsg(true);
            setModalMsg(false);
            setDisable(false);
            setTransactionStart(false);
            return;
          }
        } catch (error) {
          console.error("Error fetching transaction details:", error);
          clearInterval(id);
          getUtxos();
          setTransactionMsg(true);
          setModalMsg(false);
          setDisable(false);
          setHashStatus(false);
          return;
        }
      }, 6000);

      // Cleanup function to clear the interval when the component unmounts or when the desired result is obtained
      return () => clearInterval(id);
    }
  }, [
    amountResult,
    getTokenAPI,
    getUtxos,
    hashId,
    hashStatus,
    result,
    setDisable,
    tokenAmount,
    userToken,
  ]);

  /* my code */

  useEffect(() => {
    if (hashId && hashStatus) {
      const obj = {
        hash: hashId,
        count: `${tokenAmount}`,
      };
      /* make reliable promies to make transation request to server and blockchain */
      addAmount(userToken, obj)
        .then((response) => {
          //  console.log("response***", response.data)
          if (response) {
            setAmountResult(response.data);
          }
        })
        .catch((error) => {
          // console.log("Error******", error);
        });
    }
  }, [hashId, hashStatus, setDisable, tokenAmount, userToken]);

  // Add Token api call
  const handleAddTokenTransaction = async () => {
    // debugger;
    try {
      let lovelaceExist = await fetchedLovelace(walletAdd);
      if (lovelaceExist) {
        try {
          const updateAmount = tokenAmount;
          const txBuilder = await initTransactionBuilder();

          // company wallet address
          // "addr_test1qr4966lcxavnmua5tcmd6etzlp9htws4nrdqlgu6xp39fw77y4dpu2ljl39jh8qwfxuv58lmrm3rvt52cgnmxstu8rpqy0n50h"
          // const shelleyOutputAddrexxss = Address.from_bech32("addr1qxnwxwlxrq9p58c4mfmxdh45zpmu5wfhflwhp69cq7v667h7cvpcq5rkwhjpjmandtq39es7yxvluyts9kfgjcajg24s9nefvx"); // community wallet address
          // const shelleyOutputAddress = Address.from_bech32(communityWalletAdd); // community wallet address
          const shelleyOutputAddress = Address.from_bech32(
            "addr_test1qznwxwlxrq9p58c4mfmxdh45zpmu5wfhflwhp69cq7v667h7cvpcq5rkwhjpjmandtq39es7yxvluyts9kfgjcajg24sx9yfqe"
          ); // test wallet address
          // const shelleyOutputAddress = Address.from_bech32(
          //   "addr_test1qq5ye497qlnevs9u63vv3dwsaul38kuu0t2s0s7th3kdaphzmkzzng0t5k36rpz5z65t4lrqtauxngpa0drxtvp9h75qhu24cx"
          // ); // test wallet address

          getUtxos();
          const shelleyChangeAddress = Address.from_bech32(walletAdd);
          txBuilder.add_output(
            TransactionOutput.new(
              shelleyOutputAddress,
              Value.new(BigNum.from_str(`${updateAmount * 1000000}`))
            )
          );

          const txUnspentOutputs = await getTxUnspentOutputs();
          txBuilder.add_inputs_from(txUnspentOutputs, 2);
          txBuilder.add_change_if_needed(shelleyChangeAddress);

          const txBody = txBuilder.build();

          const transactionWitnessSet = TransactionWitnessSet.new();

          const tx = Transaction.new(
            txBody,
            TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes())
          );

          let txVkeyWitnesses = await API.signTx(
            Buffer.from(tx.to_bytes(), "utf8").toString("hex"),
            true
          );

          txVkeyWitnesses = TransactionWitnessSet.from_bytes(
            Buffer.from(txVkeyWitnesses, "hex")
          );
          setTransactionStart(true);
          transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());
          // console.warn(
          //   `Warning*********************${txVkeyWitnesses.vkeys()}`
          // );

          const signedTx = Transaction.new(tx.body(), transactionWitnessSet);

          const transactionHash = await API.submitTx(
            Buffer.from(signedTx.to_bytes(), "utf8").toString("hex")
          );
          localStorage.setItem("pendingTransactionHash", transactionHash);
          const hash = transactionHash;

          if (hash) {
            setHashId(hash);
            setHashStatus(true);
            setResult(null);
          }
        } catch (error) {
          // debugger;
          console.error("Error********", error);
          if (error.message?.split(":")[4]?.split(",")[0] === `"testnet"`) {
            setTransactionMessage(
              "Please switch the appropriate network for doing the transactions"
            );
            setModalMsg(
              "Please switch the appropriate network for doing the transactions"
            );
            getUtxos();
            setTransactionMsg(true);
            setDisable(false);
            setTransactionStart(false);
            return;
          }
          if (
            error.code === -1 &&
            error.info ===
              "Inputs do not conform to this spec or are otherwise invalid."
          ) {
            setTransactionMessage(
              "Please switch the appropriate network for doing the transactions"
            );
            setModalMsg(
              "Please switch the appropriate network for doing the transactions"
            );
            getUtxos();
            setTransactionMsg(true);
            setDisable(false);
            setTransactionStart(false);
            return;
          }

          if (
            error.code === 2 &&
            error.info === "Wallet could not send the tx."
          ) {
            console.warn("Code 2 error get"); // which generate try again problem
            setTimeout(() => {
              getUtxos();
              handleAddTokenTransaction();
            }, 1000);
            return;
          }

          // Handle error : When user enter max which they have
          if (error === "UTxO Balance Insufficient[x]") {
            if (checkUtxosArrayBalance > 2) {
              setTransactionMessage("Balance Insufficient");
              setModalMsg("Balance Insufficient");
              setTransactionMsg(true);
              setDisable(false);
              setTransactionStart(false);
              checkUtxosArrayBalance = 0;
              return;
            }
            setTimeout(() => {
              checkUtxosArrayBalance++;
              getUtxos();
              handleAddTokenTransaction();
            }, 1000);
            return;
          }

          if (
            error.code === -3 ||
            error.info === "User declined to sign the transaction." ||
            error.info === "user declined to sign tx" ||
            error.info === "user declined tx" ||
            error.info ===
              "The request was refused due to lack of access - e.g. wallet disconnects."
          ) {
            setTimeout(() => {
              setTransactionMessage("User declined to sign the transaction");
              setModalMsg("User declined to sign the transaction");
              getUtxos();
              setTransactionMsg(true);
              setDisable(false);
              setTransactionStart(false);
            }, 1000);
            return;
          }
          if (error === "UTxO Balance Insufficient") {
            setTimeout(() => {
              setTransactionMessage("Balance Insufficient");
              setModalMsg("Balance Insufficient");
              getUtxos();
              setTransactionMsg(true);
              setDisable(false);
              setTransactionStart(false);
            }, 1000);
            return;
          }

          // if (error.code === -3) {
          //   setTransactionMessage("User declined the transaction.");
          //   setModalMsg("User declined the transaction.");
          //   getUtxos();
          //   setTransactionMsg(true);
          //   setDisable(false);
          //   setTransactionStart(false);
          //   return;
          // }

          getUtxos();
          setTransactionMsg(true);
          setDisable(false);
          setModalMsg(false);
          setTransactionStart(false);
        }
      } else {
        setTransactionMessage("Balance Insufficient");
        setModalMsg("Balance Insufficient");
        setTransactionMsg(true);
        setDisable(false);
        setTransactionStart(false);
      }
    } catch (error) {
      console.error("Error*****fetching lovelace", error);
    }
    // debugger;
  };

  // Increase token
  const increaseToken = () => {
    audio.play();
    if (heading === "Redeem token") {
      if (creditPoints < 2) {
        setTokenAmount(1);
      } else if (tokenAmount === creditPoints - 1) {
        setTokenAmount(creditPoints - 1);
      } else if (tokenAmount > creditPoints) {
        setTokenAmount(creditPoints - 1);
      } else {
        setTokenAmount((prev) => Number(prev) + 1);
      }
    } else {
      if (tokenAmount >= 5000) {
        setTokenAmount(5000);
      } else {
        setTokenAmount((prev) => Number(prev) + 1);
      }
    }
  };

  // Decrease token
  const decreaseToken = () => {
    audio.play();
    if (Number(tokenAmount) <= 1) {
      return;
    }
    setTokenAmount((prev) => Number(prev) - 1);
  };

  // Redeem Token api call
  const handleRedeemTokenTransaction = async () => {
    if (tokenAmount > creditPoints) {
      toast("Insufficent amount!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      setDisable(false);
      setMainBtnDisable(false);
      return;
    }
    if (creditPoints < 2) {
      setTransactionProgress(true);
      setTransactionMsg(true);
      setModalMsg("1 ADA");
      setDisable(false);
      return;
    }
    setTransactionProgress(true);
    setTransactionStart(true);
    const obj = {
      wallet_address: walletAdd,
      count: `${tokenAmount}`,
    };
    // console.log("Reach to setTimeOut");
    // const prevTransaction = await getTransactionHistory();

    getTransactionHistory(userToken)
      .then((result) => {
        // debugger;
        const encryptedTransactoinArray = result.filter((item) => {
          const decryptedData = JSON.parse(decryption(item.encryptedData));
          if (item.encryptedData && item.Type === "1" && item.status === "2") {
            return decryptedData;
          }
          return false;
        });
        return encryptedTransactoinArray.length > 0 ? false : true;
      })
      .then(async (isPrevTransactionDone) => {
        // console.log("isPrevTransactionDone", isPrevTransactionDone);
        // debugger;
        if (isPrevTransactionDone) {
          const result = await redeemTokenRequest(userToken, obj);
          // console.log("redeem result", result);
          if (result?.Success) {
            getTokenAPI();
            setTransactionMsg(true);
            setTransactionStart(false);
            setModalMsg(true);
            setDisable(false);
          } else if (result?.name === "BlockfrostServerError") {
            // console.log("Blockfrost Error **************", result?.name);
            getTokenAPI();
            setTransactionMsg(true);
            setTransactionStart(false);
            setModalMsg("BlockfrostServerError");
            setDisable(false);
          } else {
            getTokenAPI();
            setTransactionMsg(true);
            setTransactionStart(false);
            setModalMsg(false);
            setDisable(false);
          }
        } else {
          getTokenAPI();
          setTransactionMsg(true);
          setTransactionStart(false);
          // setTransactionMessage("Please try after previous transaction done!");
          setModalMsg("Please try after previous transaction done");
          setDisable(false);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        getTokenAPI();
        setTransactionMsg(true);
        setTransactionStart(false);
        // setModalMsg("Please try after previous transaction done!");
        setDisable(false);
      });
  };

  const handleTransaction = async () => {
    // debugger;
    // getUtxos();
    try {
      if (tokenAmount === "") {
        return;
      }
      setMainBtnDisable(true);
      setDisable(true);
      audio.play();
      let usedAddress;
      if (activeWalletName === "nami") {
        const raw = await API.getUsedAddresses();
        const rawFirst = raw[0];
        usedAddress = Address.from_bytes(
          Buffer.from(rawFirst, "hex")
        ).to_bech32();
        if (walletAdd !== usedAddress) {
          setTransactionProgress(true);
          setTransactionMsg(true);
          setModalMsg(
            "Your wallet address has been changed! Please login again"
          );
          setDisable(false);
          return;
        }
      } else {
        const raw = await API.getChangeAddress();
        usedAddress = Address.from_bytes(Buffer.from(raw, "hex")).to_bech32();
      }

      if (heading === "Add token") {
        setTransactionProgress(true);
        if (Utxos.length > 0) {
          setClickonAddButton(true);
          handleAddTokenTransaction();
        } else {
          setTransactionMsg(true);
          setModalMsg("Insufficient wallet history/balance found");
          setDisable(false);
        }
        getUtxos();
      } else {
        handleRedeemTokenTransaction(usedAddress);
      }
    } catch (error) {
      // console.log("Error**", error);
      if (error.message === "account changed") setTransactionProgress(true);
      setTransactionMsg(true);
      setModalMsg("Your wallet address has been changed! Please login again");
      setDisable(false);
      return;
    }
  };

  // console.log("modal msg", modalMsg);

  const handleAfterTransactionPopUp = () => {
    audio.play();
    setTransactionProgress(false);
    setMainBtnDisable(false);
    setTransactionMsg(false);
    setDisable(false);
  };

  // Handle Token amount enter
  const handleTokeAmount = (e) => {
    if (e.target.value === "") {
      setTokenAmount("");
      return;
    }
    const decimalValue = Number(e.target.value).toFixed(2);
    const numericValue = Number(decimalValue);
    if (numericValue <= 0 || creditPoints < 2) {
      setTokenAmount(1);
      return;
    }
    if (
      (numericValue >= creditPoints || creditPoints - numericValue < 1) &&
      heading === "Redeem token"
    ) {
      setTokenAmount(creditPoints - 1);
      return;
    }
    if (numericValue >= 5000 && heading === "Add token") {
      setTokenAmount(5000);
      return;
    }
    setTokenAmount(numericValue);
  };

  // console.log(tokenAmount);

  return (
    <>
      <h1
        className={
          heading === "Add token"
            ? "token-heading"
            : "redeem-heading token-heading"
        }
        style={{ marginBottom: transactionProgress ? "50px" : "0" }}
      >
        {heading}
      </h1>
      <div
        className={
          transactionProgress && !theme
            ? "flex blur2"
            : transactionProgress && theme
            ? "flex blur3"
            : "flex"
        }
        style={{ flexDirection: "column", width: "100%", position: "relative" }}
      >
        <p className="token-count">enter your token count</p>

        <div className="token-counter flex">
          <img
            src={minus}
            alt="minus-token"
            className="minus-token"
            onClick={decreaseToken}
          />
          <div className="token-amount-box-border flex">
            <div className="token-amount-bg"></div>
            <input
              type="number"
              className="token-value"
              value={tokenAmount}
              onChange={(event) => handleTokeAmount(event)}
              max={heading === "Add token" ? 5000 : creditPoints - 1}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
            />
          </div>
          <img
            src={plus}
            alt="plus-token"
            className="plus-token"
            onClick={increaseToken}
          />
        </div>
      </div>

      {transactionProgress && (
        <div
          className="transaction-box flex"
          style={{
            boxShadow: theme
              ? "none"
              : "0px 0px 25.83387px 0px rgba(255, 255, 255, 0.50)",
          }}
        >
          {transactionMsg ? (
            <p className="transaction-txt">
              {modalMsg ===
              "Your wallet address has been changed! Please login again"
                ? "Your wallet address has been changed! Please login again"
                : heading === "Add token" && modalMsg === true
                ? "Tokens have added successfully to your account"
                : heading === "Add token" && !modalMsg
                ? "Insufficient funds in your account."
                : heading === "Add token" &&
                  modalMsg === "User declined to sign the transaction"
                ? "User declined to sign the transaction"
                : heading === "Add token" && modalMsg === "Payment required"
                ? "Something went wrong Try again after some time"
                : heading === "Add token" && modalMsg === "Balance Insufficient"
                ? "Balance Insufficient"
                : heading === "Add token" &&
                  modalMsg === "Insufficient wallet history/balance found"
                ? "Insufficient wallet history/balance found"
                : heading === "Add token" &&
                  modalMsg ===
                    "Please switch the appropriate network for doing the transactions"
                ? "Please switch the appropriate network for doing the transactions"
                : heading === "Redeem token" && modalMsg === "1 ADA"
                ? `Sorry! You can't redeem token, At least 1 ADA should be available in your game wallet at all time`
                : heading === "Redeem token" &&
                  modalMsg === "Please try after previous transaction done"
                ? "Please try after previous transaction done"
                : modalMsg === "BlockfrostServerError"
                ? "Token not redeem please try again"
                : !modalMsg
                ? "Insufficient balance in community wallet"
                : "Tokens redeem request has sent successfully"}
            </p>
          ) : transactionStart ? (
            <>
              <p className="transaction-txt pending">
                Blockchain is making transaction, please do not refresh the
                page.
              </p>
              <div className="loader"></div>
            </>
          ) : (
            <div className="loader loader-2"></div>
          )}
          <Close
            onClick={() => handleAfterTransactionPopUp()}
            disable={disable}
          />
        </div>
      )}

      <button
        onClick={handleTransaction}
        className="transaction-btn"
        disabled={
          heading === "Add token" && clickOnAddButton ? true : mainBtnDisable
        }
      >
        <img src={btnSrc} alt="token-btns" className="token-btn" />
      </button>

      <Close onClick={onClick} disable={mainBtnDisable} />
    </>
  );
};

export default TokenBox;
